<template>
  <v-container
    style="max-width: 900px"
  >
    <v-layout
      row
      wrap
      mt-4
    >
      <v-flex
        xs12
      >
        <p
          :class="$vuetify.breakpoint.smAndUp ? 'title-settings' : 'title-settings-responsive' "
        >
          Configuraciones
        </p>
      </v-flex>
      <v-flex
        xs12
      >
        <template
          v-if="!changeEmail"
        >
          <v-card
            class="card-style"
          >
            <v-layout
              justify-space-between
              row
              wrap
            >
              <v-flex
                xs12
                sm8
                align-self-start
              >
                <v-card-title
                  class="headline"
                >
                  <template
                    v-if="$vuetify.breakpoint.smAndUp"
                  >
                    <v-icon
                      color="info2"
                      style="margin: 0"
                      class="mr-3"
                    >
                      fad fa-user-friends
                    </v-icon>
                  </template>
                  <p
                    :class="!$vuetify.breakpoint.smAndDown ?
                      'sub-title-settings' : 'sub-title-settings-responsive' "
                  >
                    Cambiar correo electrónico
                  </p>
                </v-card-title>
                <p
                  :class="!$vuetify.breakpoint.smAndDown ?
                    'text-card ml-5' : 'ml-3' "
                >
                  Tu correo actual es {{ userDetails.email }}
                </p>
              </v-flex>
              <v-layout
                column
                :align-end="$vuetify.breakpoint.smAndUp"
              >
                <v-flex
                  xs12
                  sm3
                  style="margin: 0"
                  mt-3
                >
                  <v-btn
                    :class="$vuetify.breakpoint.smAndUp ?
                      'text-none btn-card' : 'text-none btn-card-responsive'"
                    block
                    color="info2"
                    @click="changeEmail=true"
                  >
                    <p
                      class="button-text"
                    >
                      Cambiar correo
                    </p>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card>
        </template>
        <template
          v-else
        >
          <v-card
            class="card-style"
          >
            <v-layout
              wrap
            >
              <v-flex
                xs12
                sm8
                align-self-start
              >
                <v-card-title
                  class="headline"
                >
                  <template
                    v-if="$vuetify.breakpoint.smAndUp"
                  >
                    <v-icon
                      color="info2"
                      style="margin: 0"
                      class="mr-3"
                    >
                      fad fa-user-friends
                    </v-icon>
                  </template>
                  <p
                    :class="!$vuetify.breakpoint.smAndDown ?
                      'sub-title-settings' : 'sub-title-settings-responsive' "
                  >
                    Cambiar correo electrónico
                  </p>
                </v-card-title>
              </v-flex>
              <v-flex
                xs12
                sm7
                :class="$vuetify.breakpoint.smAndUp ?
                  'mt-4 ml-4' : '' "
              >
                <v-text-field
                  v-model="email"
                  label="Nuevo correo electrónico"
                  outline
                  :error-messages="emailErrors"
                />
              </v-flex>
              <v-flex
                xs12
                sm7
                :class="$vuetify.breakpoint.smAndUp ?
                  'ml-4' : '' "
              >
                <v-text-field
                  v-model="emailConfirm"
                  label="Repetir correo electrónico"
                  outline
                  onpaste="return false"
                  oncopy="return false"
                  :error-messages="$v.emailConfirm.sameAsEmail ? ''
                    : 'Los correos no coinciden.'"
                />
              </v-flex>
              <template v-if="$vuetify.breakpoint.smAndUp">
                <v-layout
                  column
                  align-end
                >
                  <v-flex
                    xs12
                    sm12
                    style="margin: 0"
                    mt-2
                    align-self-end
                  >
                    <v-btn
                      flat
                      color="info2"
                      class="text-none btn-card"
                      @click="cleanInputEmail()"
                    >
                      <p class="btn-text-cancelar">
                        Cancelar
                      </p>
                    </v-btn>
                    <v-btn
                      :disabled="!$v.emailConfirm.sameAsEmail"
                      color="info2"
                      class="text-none btn-card"
                      @click.prevent="submitEmail"
                    >
                      <p class="button-text">
                        Guardar cambios
                      </p>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </template>
              <template v-else>
                <v-layout
                  column
                >
                  <v-flex
                    xs12
                    style="margin: 0"
                    mt-2
                  >
                    <v-btn
                      flat
                      block
                      color="info2"
                      class="text-none btn-card-responsive"
                      @click="cleanInputEmail()"
                    >
                      <p class="btn-text-cancelar">
                        Cancelar
                      </p>
                    </v-btn>
                    <v-btn
                      color="info2"
                      class="text-none btn-card-responsive"
                      block
                    >
                      <p class="button-text">
                        Guardar cambios
                      </p>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </template>
            </v-layout>
          </v-card>
        </template>
      </v-flex>
      <v-flex
        xs12
      >
        <v-form>
          <template
            v-if="!changePassword"
          >
            <v-card
              class="card-style"
            >
              <v-layout
                justify-space-between
                row
                wrap
              >
                <v-flex
                  xs12
                  sm8
                  align-self-start
                >
                  <v-card-title
                    class="headline"
                  >
                    <template
                      v-if="$vuetify.breakpoint.smAndUp"
                    >
                      <v-icon
                        color="info2"
                        style="margin: 0"
                        class="mr-3"
                      >
                        fas fa-lock-alt
                      </v-icon>
                    </template>
                    <p
                      :class="!$vuetify.breakpoint.smAndDown ?
                        'sub-title-settings' : 'sub-title-settings-responsive' "
                    >
                      Cambiar contraseña
                    </p>
                  </v-card-title>
                  <p
                    :class="!$vuetify.breakpoint.smAndDown ?
                      'text-card ml-5' : 'ml-3' "
                  >
                    Si eliges una contraseña segura, ayudas a proteger tu cuenta Mandomedio
                  </p>
                </v-flex>
                <v-layout
                  column
                  :align-end="$vuetify.breakpoint.smAndUp"
                >
                  <v-flex
                    xs12
                    sm3
                    style="margin: 0"
                    mt-3
                  >
                    <v-btn
                      :class="$vuetify.breakpoint.smAndUp ?
                        'text-none btn-card' : 'text-none btn-card-responsive'"
                      block
                      color="info2"
                      @click="changePassword=true"
                    >
                      <p class="button-text">
                        Cambiar contraseña
                      </p>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-card>
          </template>
          <template
            v-else
          >
            <v-card
              class="card-style"
            >
              <v-layout
                wrap
              >
                <v-flex
                  xs12
                  sm8
                  align-self-start
                >
                  <v-card-title
                    class="headline"
                  >
                    <template
                      v-if="$vuetify.breakpoint.smAndUp"
                    >
                      <v-icon
                        color="info2"
                        style="margin: 0"
                        class="mr-3"
                      >
                        fas fa-lock-alt
                      </v-icon>
                    </template>
                    <p
                      :class="!$vuetify.breakpoint.smAndDown ?
                        'sub-title-settings' : 'sub-title-settings-responsive' "
                    >
                      Cambiar contraseña
                    </p>
                  </v-card-title>
                </v-flex>
                <v-flex
                  xs12
                  sm7
                  :class="$vuetify.breakpoint.smAndUp ?
                    'mt-4 ml-4' : '' "
                >
                  <v-text-field
                    v-model="currentPass"
                    name="passwordold"
                    label="Contraseña actual"
                    type="password"
                    :autocomplete="false"
                    :error-messages="oldpasswordErrors"
                    outline
                    @input="$v.currentPass.$touch()"
                    @blur="$v.currentPass.$touch()"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm7
                  :class="$vuetify.breakpoint.smAndUp ?
                    'ml-4' : '' "
                >
                  <v-text-field
                    v-model="newPass"
                    name="passwordnew"
                    label="Nueva contraseña"
                    type="password"
                    :autocomplete="false"
                    :error-messages="newpasswordErrors"
                    outline
                    @input="$v.newPass.$touch()"
                    @blur="$v.newPass.$touch()"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm7
                  :class="$vuetify.breakpoint.smAndUp ?
                    'ml-4' : '' "
                >
                  <v-text-field
                    v-model="newPassConf"
                    name="passwordconf"
                    label="Repite nueva contraseña"
                    type="password"
                    :autocomplete="false"
                    :error-messages="confpasswordErrors"
                    outline
                    @input="$v.newPassConf.$touch()"
                    @blur="$v.newPassConf.$touch()"
                  />
                </v-flex>
                <template v-if="$vuetify.breakpoint.smAndUp">
                  <v-layout
                    column
                    align-end
                  >
                    <v-flex
                      xs12
                      sm12
                      style="margin: 0"
                      mt-2
                      align-self-end
                    >
                      <v-btn
                        flat
                        color="info2"
                        class="text-none btn-card"
                        @click="cleanInputPassword()"
                      >
                        <p class="btn-text-cancelar">
                          Cancelar
                        </p>
                      </v-btn>
                      <v-btn
                        color="info2"
                        class="text-none btn-card"
                        @click="commitChange()"
                      >
                        <p class="button-text">
                          Guardar cambios
                        </p>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </template>
                <template v-else>
                  <v-layout
                    column
                  >
                    <v-flex
                      xs12
                      style="margin: 0"
                      mt-2
                    >
                      <v-btn
                        flat
                        block
                        color="info2"
                        class="text-none btn-card-responsive"
                        @click="cleanInputPassword()"
                      >
                        <p class="btn-text-cancelar">
                          Cancelar
                        </p>
                      </v-btn>
                      <v-btn
                        color="info2"
                        class="text-none btn-card-responsive"
                        block
                        @click="commitChange()"
                      >
                        <p class="button-text">
                          Guardar cambios
                        </p>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </template>
              </v-layout>
            </v-card>
          </template>
        </v-form>
      </v-flex>
      <v-flex
        xs12
      >
        <template>
          <v-card
            class="card-style"
          >
            <v-layout
              justify-space-between
              row
              wrap
            >
              <v-flex
                xs12
                sm9
                align-self-start
              >
                <v-card-title
                  class="headline"
                >
                  <template
                    v-if="$vuetify.breakpoint.smAndUp"
                  >
                    <v-icon
                      color="info2"
                      style="margin: 0"
                      class="mr-3"
                    >
                      fad fa-bell
                    </v-icon>
                  </template>
                  <p
                    :class="!$vuetify.breakpoint.smAndDown ?
                      'sub-title-settings' : 'sub-title-settings-responsive' "
                  >
                    Notificaciones
                  </p>
                </v-card-title>
                <div
                  :class="!$vuetify.breakpoint.smAndDown ?
                    'text-card ml-5' : 'ml-3' "
                >
                  <v-switch
                    v-model="notificationConsentEmail"
                    color="info2"
                    @change="submitDetails()"
                  >
                    <template v-slot:label>
                      <p
                        class="text-card ml-2"
                        style="margin: 0"
                      >
                        Recibir notificaciones desde la plataforma de empleos
                      </p>
                    </template>
                  </v-switch>
                  <v-switch
                    v-model="marketingNotification"
                    color="info2"
                    @change="submitDetails()"
                  >
                    <template v-slot:label>
                      <p
                        class="text-card ml-2"
                        style="margin: 0"
                      >
                        Recibir notificaciones de eventos, tips y más sobre empleabilidad
                      </p>
                    </template>
                  </v-switch>
                </div>
              </v-flex>
            </v-layout>
          </v-card>
        </template>
      </v-flex>
      <v-flex
        xs12
        mb-5
      >
        <template>
          <v-card
            class="card-style"
          >
            <v-layout
              justify-space-between
              row
              wrap
            >
              <v-flex
                xs12
                sm8
                align-self-start
              >
                <v-card-title
                  class="headline"
                >
                  <template
                    v-if="$vuetify.breakpoint.smAndUp"
                  >
                    <v-icon
                      color="info2"
                      style="margin: 0"
                      class="mr-3"
                    >
                      fad fa-user-shield
                    </v-icon>
                  </template>
                  <p
                    :class="!$vuetify.breakpoint.smAndDown ?
                      'sub-title-settings' : 'sub-title-settings-responsive' "
                  >
                    Privacidad
                  </p>
                </v-card-title>
                <div
                  :class="!$vuetify.breakpoint.smAndDown ?
                    'text-card ml-5' : 'ml-3' "
                >
                  <v-radio-group
                    v-model="privacyOptions"
                    :mandatory="false"
                    @change="submitDetails()"
                  >
                    <v-radio
                      color="info2"
                      value="0"
                    >
                      <template v-slot:label>
                        <p
                          class="text-card ml-2"
                          style="margin: 0"
                        >
                          <b class="title-privacy">Público</b> <br>
                          El CV es visible en otras búsquedas que realizan las empresas.
                        </p>
                      </template>
                    </v-radio>
                    <v-radio
                      class="mt-4"
                      color="info2"
                      value="1"
                    >
                      <template v-slot:label>
                        <p
                          class="text-card ml-2"
                          style="margin: 0"
                        >
                          <b class="title-privacy">Privado</b> <br>
                          El CV es visible únicamente en los avisos que has postulado
                        </p>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
              </v-flex>
            </v-layout>
          </v-card>
        </template>
      </v-flex>
      <v-flex
        v-if="false"
        xs12
        mb-5
      >
        <template>
          <v-card
            class="card-style"
          >
            <v-layout
              justify-space-between
              row
              wrap
            >
              <v-flex
                xs12
                sm9
                align-self-start
              >
                <v-card-title
                  class="headline"
                >
                  <template
                    v-if="$vuetify.breakpoint.smAndUp"
                  >
                    <v-icon
                      color="info2"
                      style="margin: 0"
                      class="mr-3"
                    >
                      fad fa-trash
                    </v-icon>
                  </template>
                  <p
                    :class="!$vuetify.breakpoint.smAndDown ?
                      'sub-title-settings' : 'sub-title-settings-responsive' "
                  >
                    Eliminar cuenta
                  </p>
                </v-card-title>
                <p
                  :class="!$vuetify.breakpoint.smAndDown ?
                    'text-card ml-5' : 'ml-3' "
                >
                  Al eliminar la cuenta, perdarás toda la información que hayas guardado. <br>
                  Si vuelves a ingresar a Mandomedio, se creará una cuenta totalmente nueva.
                </p>
              </v-flex>
              <v-layout
                column
                :align-end="$vuetify.breakpoint.smAndUp"
              >
                <v-flex
                  xs12
                  sm3
                  style="margin: 0"
                  mt-3
                >
                  <v-btn
                    :class="$vuetify.breakpoint.smAndUp ?
                      'text-none btn-card' : 'text-none btn-card-responsive'"
                    block
                    color="info2"
                  >
                    <p class="button-text">
                      Eliminar cuenta
                    </p>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import {
  required, sameAs, minLength, not,
} from 'vuelidate/lib/validators';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'ProfileSettings',
  components: {
  },
  mixins: [validationMixin, JWTConfigMixin],
  data() {
    return {
      changeEmail: false,
      changePassword: false,
      userDetails: null,
      fieldErrors: null,
      email: '',
      emailConfirm: '',
      currentPass: '',
      newPass: '',
      newPassConf: '',
      notificationConsentEmail: '',
      marketingNotification: '',
      privacyOptions: '',
      public: 0,
      private: 1,
    };
  },
  validations: {
    emailConfirm: {
      sameAsEmail: sameAs('email'),
    },
    currentPass: { required, minLength: minLength(8) },
    newPass: { required, minLength: minLength(8), differentAsCurrent: not(sameAs('currentPass')) },
    newPassConf: { sameAsPassword: sameAs('newPass') },
  },
  computed: {
    notificationConsent() {
      return this.notificationConsentEmail;
    },
    marketingNotificationConsent() {
      return this.marketingNotification;
    },
    emailErrors() {
      return (this.fieldErrors ? this.fieldErrors.email : []);
    },
    oldpasswordErrors() {
      const errors = [];
      if (!this.$v.currentPass.$dirty) return errors;
      if (!this.$v.currentPass.required) errors.push('Campo requerido.');
      if (!this.$v.currentPass.minLength) errors.push('La contraseña debe contener al menos 8 caracteres.');
      return errors;
    },
    newpasswordErrors() {
      const errors = [];
      if (!this.$v.newPass.$dirty) return errors;
      if (!this.$v.newPass.required) errors.push('Campo requerido.');
      if (!this.$v.newPass.minLength) errors.push('La contraseña debe contener al menos 8 caracteres.');
      if (!this.$v.newPass.differentAsCurrent) errors.push('La contraseña debe ser distinta a la actual.');
      return errors;
    },
    confpasswordErrors() {
      const errors = [];
      if (!this.$v.newPass.$dirty) return errors;
      if (!this.$v.newPassConf.sameAsPassword) errors.push('La contraseñas no coinciden.');
      return errors;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadSelf();
    });
  },
  methods: {
    cleanInputEmail() {
      this.email = '';
      this.emailConfirm = '';
      this.changeEmail = false;
      this.newPass = '';
      this.currentPass = '';
      this.newPassConf = '';
    },
    cleanInputPassword() {
      this.changePassword = false;
      this.newPass = '';
      this.currentPass = '';
      this.newPassConf = '';
    },
    commitChange() {
      this.$store.commit('adminSnackbarDown');
      if (this.$v.$invalid) {
        this.$store.commit('adminSnackbarUp', 'Verifique que los datos del formulario sean correctos.');
        return;
      }

      const payload = {
        old_password: this.currentPass,
        new_password: this.newPass,
      };
      this.$store.commit('setLoading');
      axios.put(this.$store.state.endpoints.offers.admin.changePassword, payload, this.jwtConfig)
        .then(resp => {
          this.changePassword = false;
          this.$store.commit('adminSnackbarUp', resp.data.response);
          this.$store.commit('unsetLoading');
          this.cleanInputPassword();
        })
        .catch(err => {
          this.$store.commit('adminSnackbarUp', err.response.data.response);
          this.fieldErrors = err.response.data.response;
          this.$store.commit('unsetLoading');
        });
    },
    loadSelf() {
      this.$store.commit('setLoading');
      axios.get(this.$store.state.endpoints.candidates.getSelf,
        this.jwtConfig)
        .then(resp => {
          axios.get(this.$store.state.endpoints.candidates.getCHD,
            this.jwtConfig)
            .then(() => {
              this.userDetails = resp.data;
              this.notificationConsentEmail = this.userDetails.notification_consent;
              this.marketingNotification = this.userDetails.marketing_notification_consent;
              this.privacyOptions = this.userDetails.privacy_cv.toString();
              this.$store.commit('unsetLoading');
            });
        })
        .catch(() => {
          this.$router.push({ name: 'candidate:offers' });
        });
    },
    submitEmail() {
      this.fieldErrors = null;
      this.$store.commit('setLoading');
      const fd = new FormData();
      fd.append('email', this.email);
      axios.patch(this.$store.state.endpoints.candidates.getSelf,
        fd, this.jwtConfig)
        .then(response => {
          this.$store.commit('updateToken', response.data.token);
          this.$store.commit('unsetLoading');
          this.$store.commit('adminSnackbarUp', 'Email editado con éxito.');
          this.$emit('done');
          this.changeEmail = false;
          this.userDetails.email = this.email;
          this.cleanInputEmail();
          axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
            .then(resp => {
              this.$store.commit('setLoginDetails', resp.data);
            });
        }).catch(err => {
          this.$store.commit('unsetLoading');
          this.fieldErrors = err.response.data;
        });
    },
    submitDetails() {
      this.fieldErrors = null;
      this.$store.commit('setLoading');
      const fd = new FormData();
      fd.append('notification_consent', this.notificationConsentEmail);
      fd.append('marketing_notification_consent', this.marketingNotification);
      fd.append('privacy_cv', this.privacyOptions);
      fd.append('email', this.userDetails.email);
      axios.patch(this.$store.state.endpoints.candidates.getSelf,
        fd, this.jwtConfig)
        .then(response => {
          this.$store.commit('updateToken', response.data.token);
          this.$store.commit('unsetLoading');
          this.$store.commit('adminSnackbarUp', 'Perfil editado con éxito.');
          this.loadSelf();
          axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
            .then(resp => {
              this.$store.commit('setLoginDetails', resp.data);
            });
        }).catch(err => {
          this.$store.commit('unsetLoading');
          this.fieldErrors = err.response.data;
        });
    },
  },
};
</script>
<style scoped>
.title-settings {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
  margin: 0;
}
.title-settings-responsive {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
  margin: 0;
}
.sub-title-settings{
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0;
}
.sub-title-settings-responsive{
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0;
}
.button-text {
  color: #FFFFFF;
  margin: 0;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
}
.text-card {
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
}
.btn-card {
  height: 42px;
  width: 162px;
  border-radius: 6px;
  background-color: rgba(0,0,0,0);
}
.btn-card-responsive {
  border-radius: 6px;
  background-color: rgba(0,0,0,0);
}
.btn-text-cancelar {
  color: #30B0BF;
  margin: 0;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
}
.card-style {
  margin-top: 20px;
  padding: 30px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.11);
  border-radius: 6px;
}
.title-privacy {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  margin: 0;
  line-height: 32px;
}
</style>
